import '@/features/EventManager';
import '@/assets/css/index.css';
import 'tailwindcss/tailwind.css';

import { autoAnimatePlugin } from '@formkit/auto-animate/vue';
import { QueryClient, useQuery, VueQueryPlugin } from '@tanstack/vue-query';
import flagsmith from 'flagsmith';
import Maska from 'maska';
import { createApp } from 'vue';

import { FLAGSMITH_API, FLAGSMITH_ENV_ID } from '@/config';
import { useAuthMethods } from '@/features/Auth';
import { setupBarcode } from '@/features/Barcode';

import App from './app/App.vue';
import components from './app/components';
import focus from './directives/focus';
import preserveScrollTop from './directives/preserveScrollTop';
import { i18n } from './locales';
import { baseRoute, routerFactory } from './router';

const { rehydrateAccount } = useAuthMethods();

flagsmith.init({
  environmentID: FLAGSMITH_ENV_ID || '',
  api: FLAGSMITH_API,
  enableAnalytics: true,
  preventFetch: true,
  cacheFlags: true,
  onChange: (oldFlags) => {
    eventManager.emit('flagsmith:change', oldFlags);
  },
});

await rehydrateAccount();

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 1000 * 60 * 5 } },
});

const app = createApp(App);

app.use(routerFactory(baseRoute));
app.use(i18n);
app.use(components);
app.use(Maska);
app.use(VueQueryPlugin, { queryClient });
app.use(autoAnimatePlugin);
app.directive('preserve-scroll-top', preserveScrollTop);
app.directive('focus', focus);
setupBarcode();

app.mount('#app');
